<!--
 * @Author: Akko
 * @Description: 完成
-->
<template>
    <div class="done-box">
        <p>根据您填写的资料，您的初步评分如下</p>
        <div class="total-score">
            <span class="score">{{ sumScore }}</span>分
        </div>
        <p class="score-des">
            80分以上符合申请条件，文案老师会根据您的资料确认最终得分情况
        </p>
        <p class="categories">评分项</p>
        <el-table :data="tableData" border style="width: 100%" center>
            <el-table-column prop="age_score" label="年龄" align="center" />
            <el-table-column prop="education_score" label="学历" align="center" />
            <el-table-column prop="work_experience_score" label="工作经验" align="center" />
            <el-table-column prop="gj_work_experience_score" label="国际工作经验" align="center" />
            <el-table-column prop="talent_list_score" label="人才清单" align="center" />
            <el-table-column prop="language_score" label="语言" align="center" />
            <el-table-column prop="background_score" label="家庭" align="center" />
        </el-table>
        <div class="done-tips">
            已提交的信息可通过【基础信息-个人信息表】查看
        </div>
        <!-- 保存按钮 -->
        <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <el-button class="person-btn btn-blue" :loading="doneLoading" @click="done">完成并提交资料</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { assessment, userSubmit } from "@/api/workfow.js";
export default {
    name: "Done",
    components: {},
    data() {
        return {
            tableData: [],
            sumScore: 0,
            doneLoading: false,
        };
    },
    created() {
        assessment({
            orderId: this.$route.params.id,
        }).then((res) => {
            if (res.code === 200) {
                let resd = res.data;
                this.sumScore = resd.sumScore;
                this.tableData = [
                    {
                        age_score: resd.ageScore, //年龄
                        education_score:
                            resd.educationScoreA + resd.educationScoreB, //学历
                        work_experience_score: resd.workExperienceScoreA, //工作经验
                        gj_work_experience_score: resd.workExperienceScoreB, //国际工作经验
                        talent_list_score: resd.talentListScore, //人才清单
                        background_score:
                            resd.backgroundScoreA +
                            resd.backgroundScoreB +
                            resd.backgroundScoreC, //语言
                        language_score: resd.languageScore, //家庭
                    },
                ];
            }
        });
    },
    methods: {
        // 完成并提交
        done() {
            this.$confirm(
                "提交后您将无法编辑，请再次确认",
                "确认提交您的个人信息？",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.doneLoading = true;
                    userSubmit({
                        orderId: this.$route.params.id,
                    }).then((res) => {
                        this.doneLoading = false;
                        if (res.code === 200) {
                            this.$message.success("提交成功！");
                            localStorage.setItem("stepEnd", 1);
                            localStorage.setItem("isList", 0);
                            this.$store.commit("user");
                            this.$router.replace({
                                name: "JumpPage",
                            });
                        } else {
                            this.$message.success(res.msg);
                        }
                    });
                })
                .catch(() => {});
        },
        // 返回上一步
        goPre() {
            this.$router.replace({
                name: "step6",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.done-box {
    width: 960px;
    margin: 108px auto 0;
    font-size: 14px;
    color: #303133;

    > p {
        margin-bottom: 16px;
    }
}

.total-score {
    width: 100%;
    text-align: center;
    span.score {
        display: inline-block;
        color: #f56c6c;
        font-size: 24px;
        font-weight: bold;
        margin-right: 3px;
    }
}

.score-des {
    text-align: center;
    color: #606266;
    margin-bottom: 24px;
}

.categories {
    font-weight: bold;
    font-size: 16px;
}

.done-tips {
    margin-top: 16px;
    color: #606266;
}
</style>
